/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import tgLogoWhite from '@images/emailVerification/tgLogoWhite.svg';
import checkBadge from '@images/emailVerification/checkBadge.svg';
import dangerBadge from '@images/emailVerification/dangerBadge.svg';
import Button from '@components/UI/Button';
import '@scss/_email-verification.scss';

import SEO from '../components/seo';

export default function EmailVerification({ location }) {
  const [data, setData] = useState({});
  const params = new URLSearchParams(location.search);
  const verifiedKey = params.get('verifiedKey');

  useEffect(() => {
    if (!verifiedKey) {
      goToHomePage();
    } else {
      getData();
    }
  }, [verifiedKey]);

  const goToHomePage = () => {
    window.location.href = '/';
  };

  const getData = async () => {
    const response = await axios.get(
      `${process.env.EMAIL_VERIFY_API_URL}/tg/user/api/v1/users/confirm-email?VerifiedKey=${verifiedKey}`,
    );

    if (response?.data?.data && typeof response?.data?.data === 'object') {
      setData(response.data.data);

      return;
    }

    goToHomePage();
  };

  const content = useMemo(() => {
    if (data?.isSuccess && !data?.isSuccessNotification) {
      return {
        icon: checkBadge,
        title: 'E-mail adresin doğrulanmıştır.',
        subTitle: '',
        buttonText: 'Tıkla Gelsin’e Git',
      };
    }

    if (data?.isSuccess && data?.isSuccessNotification) {
      return {
        icon: checkBadge,
        title: 'E-mail adresin doğrulanmıştır.',
        subTitle: 'Artık fırsatlardan ilk senin haberin olacak. Takipte kal :)',
        buttonText: 'Tıkla Gelsin’e Git',
      };
    }

    if (data?.isUnmatchedUser) {
      return {
        icon: dangerBadge,
        title:
          'E-mail adresin uygulamadaki güncel Email adresin olmadığından dolayı onaylayamadık, uygulamadan kontrol edip, hemen tekrar deneyebilirsin.',
        subTitle: '',
        buttonText: 'Tekrar Dene',
      };
    }

    if (data?.isExpired) {
      return {
        icon: dangerBadge,
        title:
          '48 saat içerisinde doğrulama yapmadığınızdan dolayı bu mail geçersiz olmuştur. Profilinizdeki Kullanıcı Bilgilerim alanındaki E-mail Adresini Doğrula butonuna basarak tekrar doğrulama maili alabilirsiniz.',
        subTitle: '',
        buttonText: 'Tıkla Gelsin’e Git',
      };
    }
  }, [data]);

  const onPress = () => {
    window.location.href = 'tiklagelsin://web/home';

    setTimeout(() => {
      window.location.href = '/';
    }, 1000);
  };

  return (
    <>
      <SEO title="Email Doğrulama" siteUrl={location?.pathname} />
      <div className="email-verification">
        <div className="header">
          <div className="logo" onClick={goToHomePage}>
            <img src={tgLogoWhite} alt="Tıkla Gelsin Logo" width="80" height="44.5" />
          </div>
        </div>
        <div className="container-content">
          <div className="content">
            {content?.icon && <img src={content?.icon} alt="email verification badge" width="118" height="118" />}
            <p className="title">{content?.title}</p>
            {content?.subTitle && <p className="description">{content?.subTitle}</p>}
            {content?.buttonText && (
              <Button className="button" onPress={onPress}>
                {content?.buttonText}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

EmailVerification.propTypes = {
  location: PropTypes.object,
};
